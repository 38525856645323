<template>
  <div class="accordion" :class="{ 'accordion--active': active }">
    <div class="accordion__title" @click="active = !active">
      <p>{{ title }}</p>
      <icons icon="triangle-up" color="#00a4b3" class="icon" />
    </div>
    <div class="accordion__body">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
import icons from "@/components/elements/Icons.vue";

export default {
  components: { icons },
  data: () => ({
    active: true,
  }),
  props: {
    title: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.active = this.isActive;
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";
.accordion {
  border-bottom: 1px solid $gray_1;
  padding: 8px 0;
  &.accordion--active {
    .icon {
      transform: rotate(180deg);
    }
    .accordion__body {
      display: block;
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: $main_2;
      margin-bottom: 0;
    }
  }
  &__body {
    display: none;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $dark_blue_3;
  }
}
</style>
