<template>
  <div class="detail-package pt-3 pt-md-0">
    <!-- <section
      class="px-3 pt-md-3 pb-0 px-md-5 pt-md-5 pb-md-0 mt-0 mt-md-0 mt-lg-0"
    > -->
    <NavbarOne
      class="card-top d-md-none d-lg-none"
      id="card-top"
      showNavbar
      showCart
      showCartDesktop
      showSearch
    >
    </NavbarOne>
    <!-- </section> -->
    <v-card class="ma-3 mt-16 ma-md-5">
      <CustomSection>
        <!-- Detail Package {{ $route.params.id }} -->
        <HeadingCard
          class="mb-2 pt-3"
          color="soft-blue"
          bold
          :title="detailPackage.name"
        />
        <h4 class="price">{{ formatPrice(defaultPrice) }}</h4>

        <v-row>
          <v-col cols="12" md="9">
            <p class="description mb-0" style="color: #1f3c87">
              {{ detailPackage[`description_${$i18n.locale}`] }}
            </p>
          </v-col>
          <v-col cols="12" md="3" class="mb-2 text-right">
            <!--
              <v-btn class="button mr-1 mt-1" outlined>
                Add to Cart
              </v-btn>
              -->
            <localized-link
              :to="`/patient/order-subscription/${detailPackage.id}`"
            >
              <v-btn class="button mr-1 mt-1" outlined>
                {{ $t("subscribe") }}
              </v-btn>
            </localized-link>
          </v-col>
        </v-row>

        <v-expansion-panels v-model="panel" multiple accordion>
          <v-expansion-panel>
            <v-expansion-panel-header class="header-title-acc">
              <p>{{ $t("page.buy_package.package_detail") }}</p>
              <template v-slot:actions>
                <v-icon color="primary"> mdi-menu-down </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="content-title-acc">
              <v-col cols="12">
                <v-row class="row-button">
                  <v-col
                    cols="12"
                    md="6"
                    v-for="(list, index) in detailPackage.list_test"
                    :key="index"
                    style="padding: 5px"
                  >
                    <v-dialog v-model="dialog[list.id]" width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <div class="span-popup" v-bind="attrs" v-on="on" block>
                          <div style="padding: 0px">
                            {{
                              $i18n.locale == "id"
                                ? list.name
                                : list[`name_${$i18n.locale}`]
                            }}
                          </div>
                          <div style="padding: 0px">X {{ list.qty }}</div>
                        </div>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="title-dialog">{{
                            $i18n.locale == "id"
                              ? list.name
                              : list[`name_${$i18n.locale}`]
                          }}</span>
                        </v-card-title>

                        <v-card-text class="content-dialog">
                          {{ list[`description_${$i18n.locale}`] }}
                        </v-card-text>

                        <v-divider></v-divider>
                        <accordion
                          class="accordion-dialog"
                          :title="$t('page.buy_package.examination_benefits')"
                        >
                          <template v-slot:body>
                            <p class="paragraph-dialog mb-0">
                              {{ list[`benefit_${$i18n.locale}`] }}
                            </p>
                          </template>
                        </accordion>
                        <v-divider></v-divider>
                        <accordion
                          class="accordion-dialog"
                          :title="$t('page.buy_package.patient_preparation')"
                        >
                          <template v-slot:body>
                            <p class="paragraph-dialog mb-0">
                              {{
                                $i18n.locale == "en"
                                  ? list.preparation
                                  : list[`preparation_${$i18n.locale}`]
                              }}
                            </p>
                          </template>
                        </accordion>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            class="btn-close"
                            text
                            @click="dialog[list.id] = false"
                          >
                            {{ $t("close") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-divider class="line-title"></v-divider>

          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header class="header-title-acc">
                <p>{{ $t("page.buy_package.tnc") }}</p>
                <template v-slot:actions>
                  <v-icon color="primary"> mdi-menu-down </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="content-title-prep">
                <template>
                  <p
                    class="text-justify mb-0 mr-3"
                    v-html="$t('page.buy_package.tnc_package_sub')"
                  >
                    <!-- {{ detailPackage[`benefit_${$i18n.locale}`] }} -->
                  </p>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panels>
        <v-divider class="mt-2"></v-divider>
        <HeadingCard
          class="my-2"
          color="soft-blue"
          bold
          :title="$t('page.buy_package.related_packages')"
        />

        <!-- ini trial shinta buat related package -->
        <v-row dense>
          <v-col
            cols="12"
            md="6"
            lg="4"
            class="pa-2"
            v-for="(relatedPackage, index) of detailPackage.related_package"
            :key="index"
          >
            <card-subscribe-package :data="relatedPackage" />
          </v-col>
        </v-row>
        <DialogMenu
          :tampilkan="dialogMenu"
          @toggleDialog="dialog => toggleDialogMenu(dialog)"
        />
      </CustomSection>
    </v-card>
  </div>
</template>

<script>
import CustomSection from "@/components/v2/section/index.vue";
import HeadingCard from "@/components/Heading/HeadingCard.vue";
import Accordion from "@/components/v2/accordion/index.vue";
import CardSubscribePackage from "@/components/v2/card/card_subscribe_package.vue";
import API from "../../../../service/api_service";
import Auth from "../../../../service/auth_service";
import DialogMenu from "@/components/v2/dialog/dialogMenu.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";

export default {
  components: {
    CustomSection,
    HeadingCard,
    Accordion,
    DialogMenu,
    NavbarOne,
    CardSubscribePackage,
  },
  data: () => ({
    dialogMenu: false,
    showCart: {
      type: Boolean,
      default: false,
    },
    showCartDesktop: {
      type: Boolean,
      default: false,
    },
    panel: [0],
    dialog: {},
    detailPackage: [],
    defaultPrice: "",
    name: "VIRTU DIGILAB MCU 1A",
    items: [
      {
        title: "VIRTU DIGILAB MCU 1A",
        realPrice: 1350000,
        discountPrice: 938000,
        subTitle: "8 Test Covered",
      },
      {
        title: "VIRTU DIGILAB MCU 2A",
        realPrice: 1350000,
        discountPrice: 1203000,
        subTitle: "18 Test Covered",
      },
      {
        title: "VIRTU DIGILAB MCU 3A",
        realPrice: 2950000,
        discountPrice: 1584000,
        subTitle: "24 Test Covered",
      },
    ],
    description_en:
      "Evaluating the quality of health in general, both concerning organ function and the state of the body metabolism by screening for the presence or absence of disorders or diseases that are often encountered and potentially dangerous, such as blood disorders, metabolic disorders (processing) of fats and sugars, impaired liver and kidney function",
    steps: [
      { step: "Get enough sleep, at least 6 hours, prior to the check-up." },
      {
        step: "Do not eat or drink at least 8 – 10 hours prior to the check-up.",
      },
      {
        step: "For females, avoid 7 days before or after the menstrual period.",
      },
    ],
    patientPreparation: "Fasting 12-14 hours",
    examinationBenefit:
      "Determine risk factors for coronary heart disease; detect metabolic syndrome; monitor the effectiveness of lipid-lowering therapy.",
    benefit_en:
      "Regular check-ups can help find potential health issues before they become a problem. When you see your doctor regularly, they are able to detect health conditions or diseases early. Early detection gives you the best chance for getting the right treatment quickly, avoiding any complications. By getting the correct health services, screenings, and treatment you are taking important steps toward living a longer, healthier life.",
    descriptionDialog:
      "Triglyceride examination is a blood test that measures the concentration of triglycerides in the blood. Triglycerides are a type of fat found in the blood. When we eat, the body converts unused calories into triglycerides which are then stored in fat cells. Triglycerides are released to produce energy when the body needs it. If the calories consumed are more than the calories used (eg for activities), then the concentration of triglycerides will increase. Increased concentrations of triglycerides in the blood can increase the risk of heart disease and be a sign of metabolic syndrome.",
  }),
  created() {
    this.getDetailPackage();
  },
  methods: {
    async getDetailPackage() {
      this.$store.commit("setLoading", true);
      try {
        const respListTest = await API.get(
          `${process.env.VUE_APP_API_TRUST}product/get?package_id=${this.$route.params.id}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        const listTest = respListTest.results;
        const respList = await API.get(
          `${process.env.VUE_APP_API_TRUST}package/subscription`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        const { results: listPackage } = respList;
        const indexPackage = listPackage.findIndex(
          item => item.id == this.$route.params.id
        );
        const relatedPackage = listPackage.filter(res => {
          return (
            res.category_id == listPackage[indexPackage].category_id &&
            res.id != listPackage[indexPackage].id
          );
        });
        this.detailPackage = {
          ...listPackage[indexPackage],
          list_test: listTest,
          related_package: relatedPackage,
        };

        this.defaultPrice =
          Object.prototype.hasOwnProperty.call(this.detailPackage, "prices") &&
          this.detailPackage.prices.length > 0
            ? this.detailPackage.prices[0].amount
            : 0;

        this.$store.commit("setLoading", false);
      } catch (error) {
        console.log(error);
        this.$store.commit("setLoading", false);
      }
    },
    formatPrice(price) {
      let val = (price / 1).toFixed(2).replace(".", ".");
      return `IDR ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    },
    async openDialog() {
      this.dialogMenu = true;
    },
    toggleDialogMenu(toggle) {
      this.dialogMenu = toggle;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/color.scss";
.span-popup {
  height: 26px;
  width: 100%;
  padding: 0 16px;
  font-size: 12px;
  color: white;
  background-color: #00a4b3;
  box-shadow: 0 3px 1px -2pxrgba (0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  justify-content: space-between;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: uppercase;
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.detail-package {
  padding: 24px 0 40px 0;
  min-height: 700px;
  overflow-x: hidden;
  a {
    text-decoration: none;
  }

  @media screen and (max-width: 960px) {
    background: white;
  }
}
@media screen and (max-width: 415px) {
  .btn-list {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    margin: 10px;
  }
}

.price {
  color: #1f3c87;
  font-size: 14px !important;
}

.description {
  font-size: 12px;
  text-align: justify;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 26px;
  min-width: 120px;
  padding: 0 16px;
  color: $main_2;
}

.v-btn__content {
  color: $dark_blue_3;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 26px;
  width: 100%;
  padding: 0 16px;
  text-transform: capitalize;
  font-size: 12px;
  color: white;
  background-color: #00a4b3;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: $main_2;
}

.title-dialog {
  font-size: 12px;
  text-transform: uppercase;
  color: $main_2;
  font-weight: bold;
}

.content-dialog {
  text-align: justify;
  font-size: 12px;
  line-height: 16px;
  margin-top: -3px;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  color: $dark_blue_3;
}

.accordion-dialog {
  margin-left: 22px;
  margin-right: 22px;
}
.paragraph-dialog {
  font-weight: 400;
  text-align: justify;
}
.closeButton {
  min-width: auto !important;
  position: absolute;
  right: 0;
  bottom: 15px;
}
.v-card__title {
  position: relative;
  padding-bottom: 0 !important;
}
.v-btn > .v-btn__content .v-icon {
  font-size: 15px;
}

.row-button {
  margin-top: -15px;
}

.button {
  color: white;
  width: 100%;
}

.detail-package {
  padding: 24px 0 40px 0;
  a {
    text-decoration: none;
  }
  .h-full {
    height: 100%;
    border: 1px solid #777777;
  }
}
.btn-close {
  width: auto !important;
  background: none !important;
  color: #00a4b3 !important;
}
//accordion
.header-title-acc {
  padding: 0 !important;
  margin-bottom: -20px !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #00a4b3;
}
.content-title-acc {
  margin-left: -23px !important;
  margin-right: -23px !important;
  margin-top: -20px !important;
}
.content-title-prep {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #1f3c87;
  margin-left: -23px !important;
  margin-right: -23px !important;
  margin-top: -20px !important;
  margin-bottom: -15px;
}
.line-title {
  margin-top: 35px;
}

.card--shadow {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.btn-list {
  margin: 10px;
}

@media only screen and (max-width: 600px) {
  .card-top {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .notification {
    height: 40px;
    border-radius: 3px;
    margin-top: -7px;
    margin-left: -46px !important;
  }
  .cart {
    margin-left: 11px;
    margin-right: 3px;
  }

  .column-back {
    margin-right: 0px;
    margin-left: -20px;
    margin-top: -6px;
  }

  .back {
    cursor: pointer;
  }

  .text-field {
    max-width: 100% !important;
    margin-left: 4px !important;
    margin-top: -3px;
  }

  .cart-right {
    margin-left: -4px !important;
    z-index: 10;
  }

  .bell-mobile {
    margin-left: 8px;
  }
  .user-card {
    margin-top: -3px;
    max-width: 100% !important;
    margin-left: -10px;
    margin-right: -23px;
  }

  .price {
    font-size: 12px !important;
  }
}

.arrow-back {
  cursor: pointer;
}

.column-back {
  margin-top: -6px;
}

.text-field {
  max-width: 70%;
  margin-right: 4px !important;
  margin-left: -30px;
  margin-top: -3px;
}

.cart-right {
  text-align: right;
  justify-content: flex-end;
  background: none;
  width: 97%;
  margin-top: -18px;
  margin-left: 10px;
}
</style>
