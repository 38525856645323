<template>
  <div class="card pa-3 pa-md-5 d-flex justify-space-between flex-column">
    <div @click="goDetail()">
      <localized-link :to="`/patient/buy-subscription/${data.id}`">
        <h4 class="card__title text-center">{{ data.name }}</h4>
      </localized-link>
      <h5 class="card__sub-title text-center">
        IDR
        {{
          data.prices != null && data.prices.length !== 0
            ? formatPrice(data.prices[0].amount)
            : "0"
        }}
      </h5>
      <p class="card__desc text-center mb-5">
        {{ `${data.total_products} ${$t("test_covered")}` }}
      </p>
      <div
        class="card__body py-2"
        v-for="(item, index) of data.detail_package"
        :key="index"
      >
        <div class="d-flex justify-space-between py-1">
          <p class="product-name mb-0 text-capitalize">
            {{ item[`name_${$i18n.locale}`].toLowerCase() }}
          </p>
          <v-icon color="#00a6b6" dense>mdi-check</v-icon>
        </div>
      </div>
    </div>
    <div class="pt-3">
      <localized-link :to="`/patient/order-subscription/${data.id}`">
        <c-button class="py-2 button-sub" fluid>{{ $t("subscribe") }}</c-button>
      </localized-link>
    </div>
  </div>
</template>

<script>
import CButton from "@/components/v2/button/index.vue";

export default {
  components: { CButton },
  props: {
    data: {
      type: Object,
      default: () => ({
        name: "Paket A 1",
        prices: [],
        total_products: "",
        product: ["product a", "product b", "product c", "product d"],
      }),
    },
  },
  methods: {
    formatPrice(price) {
      return `${Intl.NumberFormat().format(price)}.00`;
    },
    goDetail() {
      this.$router.push(
        `/${this.$i18n.locale}/patient/buy-subscription/${this.data.id}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.card {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 10px;
  background: white;
  height: 100%;
  &__title {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
  &__sub-title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    color: $main_1;
  }
  &_desc {
    font-size: 14px;
    margin: 0;
  }
  &__body {
    border-top: 0.5px solid $gray_2;
    .product-name {
      font-size: 14px;
    }
  }
  a {
    color: $gray_4;
    text-decoration: none;
  }
  .button-sub {
    font-weight: 600;
  }
}
</style>
